<template>
    <div>

        <div class="row mb-4">
            <div class="col-md-6">
                <Breadcrumbs dynamicTitle="Advanced Search" />
            </div>

            <div v-if="results.results && results.results.length" class="col-md-6 text-right">
                    <div class="d-flex flex-column flex-md-row justify-content-end align-items-center">
                <button :disabled="isBusy" @click="downloadResults(false)" class="btn btn-success btn-block-mobile btn-lg lg-shadow mr-2" :class="{disabled : isBusy}">Download CSV</button>
                <button :disabled="isBusy" @click="downloadResults(true)" class="btn btn-success btn-block-mobile btn-lg lg-shadow" :class="{disabled : isBusy}">Download PDF</button>
            </div>
            </div>
        </div>

        <div class="accordion mt-4" role="tablist">
            <div class="card">
                <div class="card-header bg-none d-flex align-items-center justify-content-between p-3 p-1" role="tab">
                    <h5 class="mb-0">Search criteria</h5>
                    <b-button v-b-toggle.accordion-1 variant="link" @click="collapsed = !collapsed"><span class="underlined">Hide</span> <img class="accordion-chevron" :class="{collapsed : collapsed}" src="@/assets/images/icons/select-chevron.svg" /></b-button>
                </div>

                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                    <div class="card-body pt-2">
                        <div class="row">
                            <div class="col-xl-11">
                                <div class="row">
                                    <div class="col-xl-4 col-lg-6">
                                        <div class="form-group">
                                            <label>Patient name</label>
                                            <input type="text" class="form-control" placeholder="Search for patient name" v-model="advancedSearchParams.queryText">
                                        </div>

                                        <div class="form-group">
                                            <label>Address</label>
                                            <input type="text" class="form-control" placeholder="Search for address" v-model="advancedSearchParams.addressText">
                                        </div>

                                        <div class="form-group">
                                            <label>Organisation</label>
                                            <select class="custom-select" v-model="advancedSearchParams.organisationId" @change="chooseOrganisation()">
                                                <option value="">Please choose an organisation</option>
                                                <option v-for="(organisation, index) in allOrganisations" :key="index" :value="organisation.id">{{ organisation.name }}</option>
                                            </select>
                                            <small v-if="errors && errors.organisationId" class="text-danger">
                                                {{ errors.organisationId[0] }}
                                            </small>
                                        </div>

                                        <div class="form-group">
                                            <label>Facility</label>
                                            <select :disabled="facilityListDisabled" class="custom-select" v-model="advancedSearchParams.facilityId">
                                                <option value="">All Facilities</option>
                                                <option v-for="(facility, index) in allFacilities" :key="index" :value="facility.id">{{ facility.name }}</option>
                                            </select>
                                        </div>

                                        <div class="form-group">
                                            <label>Health care professional</label>
                                            <select :disabled="practitionerListDisabled" class="custom-select" v-model="advancedSearchParams.practitionerId">
                                                <option value="">All HCPs</option>
                                                <option v-for="(practitioner, index) in allPractitioners" :key="index" :value="practitioner.id">{{ practitioner.name }}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-xl-4 col-lg-6">

                                        <div class="form-group">
                                            <label>Test Number</label>
                                            <input type="text" class="form-control" placeholder="Search for test number" v-model="advancedSearchParams.testNumber">
                                        </div>

                                        <div class="form-group">
                                            <div class="row align-items-end">
                                                <div class="col-md-5">
                                                    <label>Issue date from</label>
                                                    <b-form-datepicker
                                                        v-model="advancedSearchParams.issueDateFrom"
                                                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                        :locale="locale"
                                                        placeholder="Choose"
                                                        hide-header
                                                        show-decade-nav
                                                        no-flip
                                                    >
                                                    </b-form-datepicker>
                                                </div>

                                                <div class="col-md-5 mt-2 mt-md-0">
                                                    <label>Issue date to</label>
                                                    <b-form-datepicker
                                                        v-model="advancedSearchParams.issueDateTo"
                                                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                        :locale="locale"
                                                        placeholder="Choose"
                                                        hide-header
                                                        show-decade-nav
                                                        no-flip
                                                    >
                                                    </b-form-datepicker>
                                                </div>

                                                <div class="col-md-2">
                                                    <span @click="clearIssueDates()" class="text-underlined cursor-pointer d-block mb-2">
                                                        <svg class="clear-date" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 28 28">
                                                            <g id="Group_654" data-name="Group 654" transform="translate(-1030 -412)">
                                                                <g class="change-on-hover" id="Ellipse_383" data-name="Ellipse 383" transform="translate(1030 412)" fill="#dae2e7" stroke="#b5c4cf" stroke-width="1">
                                                                    <circle cx="14" cy="14" r="14" stroke="none"/>
                                                                    <circle cx="14" cy="14" r="13.5" fill="none"/>
                                                                </g>
                                                                <g id="Group_653" data-name="Group 653">
                                                                    <path id="Path_6099" data-name="Path 6099" d="M14240.6-19010.924l-10.974-10.975,2.121-2.121,10.974,10.975Z" transform="translate(-13192 19443.473)" fill="#515b62"/>
                                                                    <path id="Path_6100" data-name="Path 6100" d="M14241.661-19022.959l-10.974,10.975" transform="translate(-13192.001 19443.473)" fill="none" stroke="#515b62" stroke-width="3"/>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <div class="row align-items-end">
                                                <div class="col-md-5">
                                                    <label>Return date from</label>
                                                    <b-form-datepicker
                                                        v-model="advancedSearchParams.returnDateFrom"
                                                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                        :locale="locale"
                                                        placeholder="Choose"
                                                        hide-header
                                                        show-decade-nav
                                                        no-flip
                                                    >
                                                    </b-form-datepicker>
                                                </div>

                                                <div class="col-md-5 mt-2 mt-md-0">
                                                    <label>Return date to</label>
                                                    <b-form-datepicker
                                                        v-model="advancedSearchParams.returnDateTo"
                                                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                        :locale="locale"
                                                        placeholder="Choose"
                                                        hide-header
                                                        show-decade-nav
                                                        no-flip
                                                    >
                                                    </b-form-datepicker>
                                                </div>

                                                <div class="col-md-2">
                                                    <span @click="clearReturnDates()" class="text-underlined cursor-pointer d-block mb-2">
                                                        <svg class="clear-date" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 28 28">
                                                            <g id="Group_654" data-name="Group 654" transform="translate(-1030 -412)">
                                                                <g class="change-on-hover" id="Ellipse_383" data-name="Ellipse 383" transform="translate(1030 412)" fill="#dae2e7" stroke="#b5c4cf" stroke-width="1">
                                                                <circle cx="14" cy="14" r="14" stroke="none"/>
                                                                <circle cx="14" cy="14" r="13.5" fill="none"/>
                                                                </g>
                                                                <g id="Group_653" data-name="Group 653">
                                                                <path id="Path_6099" data-name="Path 6099" d="M14240.6-19010.924l-10.974-10.975,2.121-2.121,10.974,10.975Z" transform="translate(-13192 19443.473)" fill="#515b62"/>
                                                                <path id="Path_6100" data-name="Path 6100" d="M14241.661-19022.959l-10.974,10.975" transform="translate(-13192.001 19443.473)" fill="none" stroke="#515b62" stroke-width="3"/>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <div class="row align-items-end">
                                                <div class="col-md-5">
                                                    <label>Test date from</label>
                                                    <b-form-datepicker
                                                        v-model="advancedSearchParams.dueDateFrom"
                                                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                        :locale="locale"
                                                        placeholder="Choose"
                                                        hide-header
                                                        show-decade-nav
                                                        no-flip
                                                    >
                                                    </b-form-datepicker>
                                                </div>

                                                <div class="col-md-5 mt-2 mt-md-0">
                                                    <label>Test date to</label>
                                                    <b-form-datepicker
                                                        v-model="advancedSearchParams.dueDateTo"
                                                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                        :locale="locale"
                                                        placeholder="Choose"
                                                        hide-header
                                                        show-decade-nav
                                                        no-flip
                                                    >
                                                    </b-form-datepicker>
                                                </div>

                                                <div class="col-md-2">
                                                    <span @click="clearDueDates()" class="text-underlined cursor-pointer d-block mb-2">
                                                        <svg class="clear-date" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 28 28">
                                                            <g id="Group_654" data-name="Group 654" transform="translate(-1030 -412)">
                                                                <g class="change-on-hover" id="Ellipse_383" data-name="Ellipse 383" transform="translate(1030 412)" fill="#dae2e7" stroke="#b5c4cf" stroke-width="1">
                                                                <circle cx="14" cy="14" r="14" stroke="none"/>
                                                                <circle cx="14" cy="14" r="13.5" fill="none"/>
                                                                </g>
                                                                <g id="Group_653" data-name="Group 653">
                                                                <path id="Path_6099" data-name="Path 6099" d="M14240.6-19010.924l-10.974-10.975,2.121-2.121,10.974,10.975Z" transform="translate(-13192 19443.473)" fill="#515b62"/>
                                                                <path id="Path_6100" data-name="Path 6100" d="M14241.661-19022.959l-10.974,10.975" transform="translate(-13192.001 19443.473)" fill="none" stroke="#515b62" stroke-width="3"/>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-4 col-lg-6 offset-lg-6 offset-xl-0">
                                        <div class="form-group">
                                            <label>Threshold (mmol/L)</label>
                                            <div class="row align-items-end">
                                                <div class="col-sm-5">
                                                    <label>Min</label>
                                                    <div class="input-group">
                                                        <input v-model="advancedSearchParams.thresholdMin" type="text" class="form-control" />
                                                        <div class="input-group-append">
                                                            <span class="input-group-text">mmol/L</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-5">
                                                    <label>Max</label>
                                                    <div class="input-group">
                                                        <input v-model="advancedSearchParams.thresholdMax" type="text" class="form-control" />
                                                        <div class="input-group-append">
                                                            <span class="input-group-text">mmol/L</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-2">
                                                    <span @click="clearThresholds()" class="text-underlined cursor-pointer d-block mb-2">
                                                        <svg class="clear-date" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 28 28">
                                                            <g id="Group_654" data-name="Group 654" transform="translate(-1030 -412)">
                                                                <g class="change-on-hover" id="Ellipse_383" data-name="Ellipse 383" transform="translate(1030 412)" fill="#dae2e7" stroke="#b5c4cf" stroke-width="1">
                                                                <circle cx="14" cy="14" r="14" stroke="none"/>
                                                                <circle cx="14" cy="14" r="13.5" fill="none"/>
                                                                </g>
                                                                <g id="Group_653" data-name="Group 653">
                                                                <path id="Path_6099" data-name="Path 6099" d="M14240.6-19010.924l-10.974-10.975,2.121-2.121,10.974,10.975Z" transform="translate(-13192 19443.473)" fill="#515b62"/>
                                                                <path id="Path_6100" data-name="Path 6100" d="M14241.661-19022.959l-10.974,10.975" transform="translate(-13192.001 19443.473)" fill="none" stroke="#515b62" stroke-width="3"/>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label>Status</label>
                                            <select class="custom-select" v-model="advancedSearchParams.testStatus">
                                                <option value="">Any</option>
                                                <option value="NEW">New</option>
                                                <option value="ISSUED">Issued</option>
                                                <option value="RETURNED">Returned</option>
                                                <option value="COMPLETE">Complete</option>
                                                <option value="CANCELLED">Cancelled</option>
                                                <option value="REPORTED">Reported</option>
                                                <option value="FAILED">Failed</option>
                                                <option value="ERROR">Errored</option>
                                            </select>
                                        </div>

                                        <div class="form-group">
                                            <label>Issue method</label>
                                            <select class="custom-select" v-model="advancedSearchParams.issueMethod">
                                                <option value="">Any</option>
                                                <option :value="IssueMethod.IN_PERSON">In person</option>
                                                <option :value="IssueMethod.POST">Post</option>
                                                <option :value="IssueMethod.COLLECTION">Collection</option>
                                            </select>
                                        </div>

                                        <div class="form-group">
                                            <label>Testing for</label>
                                            <select class="custom-select" v-model="advancedSearchParams.issueReason">
                                                <option value="">Any</option>
                                                <option :value="TestIssueReason.GD">Gestational Diabetes Mellitus</option>
                                                <option :value="TestIssueReason.CF">Cystic Fibrosis</option>
                                                <option :value="TestIssueReason.TYPE1">Type 1</option>
                                                <option :value="TestIssueReason.RESEARCH_STUDY">Research Study</option>
                                            </select>
                                        </div>

                                        <div class="d-flex mt-4">
                                            <button :disabled="isBusy" @click="cancel()" onclick="this.blur();" class="btn btn-cancel btn-lg d-flex flex-grow-1 justify-content-center lg-shadow" :class="{disabled : isBusy}">Clear</button>
                                            <button :disabled="isBusy" @click="search()" onclick="this.blur();" class="btn btn-success btn-lg d-flex flex-grow-1 justify-content-center lg-shadow ml-4" :class="{disabled : isBusy}">Search</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-collapse>
            </div>
        </div>

        <div v-if="!isBusy && !results.results.length && advancedSearchParams.hasSearchBeenExecuted">
            <div class="row">
                <div class="col">
                    <div class="mt-4">
                        <div class="alert alert-info">
                            Your search query returned no results. Please try amending your search criteria
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="results.results && results.results.length">
            <div class="row mt-5">
                <div class="col">
                    <p class="h4">Search Results <small class="font-italic text-sm">{{ `Showing ${showingComputed} of ${results.totalCount}` }}</small></p>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col">
                    <b-table
                        ref="table"
                        id="table"
                        :sort-by.sync="sortBySync"
                        :sort-desc.sync="sortDesc"
                        @sort-changed="sortingChanged"
                        :no-local-sorting="true"
                        :busy.sync="isBusy"
                        class="custom-table bg-white"
                        responsive
                        no-sort-reset
                        :fields="filteredTestsFields"
                        :items="results.results"
                    >
                        <template #cell(patientName)="data">
                            <router-link class="font-weight-bold underlined text-capitalize" :to="{name: 'ViewTest', params: {oId : data.item.organisationId, tId: data.item.id}}">{{ data.item.patientName }}</router-link>
                        </template>

                        <template #cell(testNumber)="data">
                            <router-link class="font-weight-bold underlined text-capitalize" :to="{name: 'ViewTest', params: {oId : data.item.organisationId, tId: data.item.id}}">{{ data.item.testNumber }}</router-link>
                        </template>

                        <template #cell(status)="data">
                            <testStatuses :status="data.item.status" />
                        </template>

                        <template #cell(practitionerName)="data">
                            <router-link class="font-weight-bold underlined text-capitalize" :to="{name: 'ViewHcp', params: {oId : data.item.organisationId, pId: data.item.practitionerId}}">{{ data.item.practitionerName }}</router-link>
                        </template>

                        <template #cell(facilityName)="data">
                            <router-link class="font-weight-bold underlined text-capitalize" :to="{name: 'ViewFacility', params: {oId : data.item.organisationId, fId: data.item.facilityId}}">{{ data.item.facilityName }}</router-link>
                        </template>

                        <template #cell(action)="data">
                            <router-link :to="{name: 'ViewTest', params: {oId : data.item.organisationId, tId: data.item.id}}" class="btn btn-primary sm-shadow text-nowrap">View Test</router-link>
                        </template>
                    </b-table>

                    <div class="mt-4 d-flex flex-column flex-md-row align-items-center justify-content-end">
                        <div class="form-group d-flex align-items-center mb-4 mb-md-0">
                            <label class="flex-shrink-0 mr-2">Items per page</label>
                            <select class="custom-select" v-model="advancedSearchParams.pageSize" @change="handleChangePageSize()">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                            </select>
                        </div>

                        <b-pagination
                            v-model="currentPage"
                            :current-page="currentPage"
                            :per-page="results.pageSize"
                            :total-rows="results.totalCount"
                            prev-text="< Previous"
                            next-text="Next >"
                            @change="pageChange"
                            aria-controls="table"
                            hide-goto-end-buttons
                            prev-class="prev-page"
                            next-class="next-page"
                            align="right"
                            class="mb-0"
                        ></b-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import IssueMethod from '@/enums/IssueMethod'
import TestIssueReason from '@/enums/TestIssueReason'
import TestStatus from '@/enums/TestStatus'
import TestStatuses from '@/components/TestStatusesComponent.vue'
import TotalResultsMixin from '@/mixins/TotalResultsMixin'
import FormatterMixin from '@/mixins/FormatterMixin'
import Breadcrumbs from '@/partials/site-structure/BreadcrumbsComponent'
export default {
    name: 'AdvancedSearch',

    components: {
        TestStatuses,
        Breadcrumbs
    },

    mixins: [
        TotalResultsMixin,
        FormatterMixin
    ],

    data () {
        return {
            facilityListDisabled: true,
            practitionerListDisabled: true,
            collapsed: false,
            locale: 'en-GB',
            loading: false,
            isBusy: false,
            currentPage: 1,
            offset: 0,

            sortColumn: 'PATIENT_NAME',
            sortType: 'ASCENDING',
            sortBySync: 'patientName',
            sortDesc: false,
            errors: {},
            IssueMethod,
            TestIssueReason,
            TestStatus
        }
    },

    async mounted () {
        await this.getAllOrganisations()

        if (this.advancedSearchParams.organisationId !== '') {
            await this.getFacilitiesByOrganisation(this.advancedSearchParams.organisationId)
            await this.getPractitionersByOrganisation(this.advancedSearchParams.organisationId)
            this.facilityListDisabled = false
            this.practitionerListDisabled = false
        }
    },

    computed: {
        ...mapGetters({
            userId: 'UserStore/GetUserId',
            advancedSearchParams: 'SearchStore/GetAdvancedSearchParams',
            results: 'SearchStore/GetResults',
            allFacilities: 'FacilityStore/GetAllFacilities',
            allPractitioners: 'PractitionerStore/GetAllPractitioners',
            allOrganisations: 'OrganisationStore/GetAllOrganisations'
        }),

        years () {
            const currentYear = new Date().getFullYear()
            const years = []
            let startYear = 1920

            while (startYear <= currentYear) {
                years.push(startYear++)
            }

            return years
        },

        filteredTestsFields () {
            return [
                {
                    key: 'patientName',
                    label: 'Patient name',
                    variant: 'text-wrap w-15',
                    sortable: true
                },
                {
                    key: 'testNumber',
                    label: 'Test No.',
                    sortable: true
                },
                {
                    key: 'issuedDate',
                    label: 'Issue Date',
                    sortable: true,
                    formatter: 'formatDate'
                },
                {
                    key: 'returnDate',
                    label: 'Return date',
                    sortable: true,
                    formatter: 'formatDate'
                },
                {
                    key: 'dueDate',
                    label: 'Test date',
                    sortable: true,
                    formatter: 'formatDate'
                },
                {
                    key: 'gdEstimatedDeliveryDate',
                    label: 'EDD',
                    sortable: true,
                    formatter: 'formatDate'
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                },
                {
                    key: 'practitionerName',
                    label: 'Assigned HCP',
                    variant: 'text-wrap w-15',
                    sortable: true
                },
                {
                    key: 'facilityName',
                    label: 'Facility',
                    variant: 'text-wrap w-15',
                    sortable: true
                },
                {
                    key: 'dateUpdated',
                    label: 'Last Updated',
                    variant: 'text-wrap w-15',
                    formatter: 'formatDate',
                    sortable: true
                },
                {
                    key: 'action',
                    label: ''
                }
            ]
        }
    },

    methods: {
        ...mapActions({
            clearAdvancedFacilityIdAndAdvancedPractitionerIdInSearchStore: 'SearchStore/ClearAdvancedFacilityIdAndAdvancedPractitionerId',
            getFacilitiesByOrganisation: 'FacilityStore/GetFacilitiesByOrganisation',
            getPractitionersByOrganisation: 'PractitionerStore/GetPractitionersByOrganisation',
            getAllOrganisations: 'OrganisationStore/GetAllOrganisations',
            setSearchResultsInSearchStore: 'SearchStore/SetSearchResults',
            setNotification: 'NotificationStore/SetNotification',
            clearSearchStore: 'SearchStore/ClearSearchStore'
        }),

        async search () {
            this.currentPage = 1
            this.offset = 0
            await this.getResults()
        },

        async downloadResults (asPDF) {
            this.checkForm()

            if (!Object.keys(this.errors).length) {
                const chosenStatusArray = []
                if (this.advancedSearchParams.testStatus) {
                    chosenStatusArray.push(this.advancedSearchParams.testStatus)
                }

                this.isBusy = true

                const searchModel = {
                    organisationId: this.advancedSearchParams.organisationId,
                    patientText: this.advancedSearchParams.queryText,
                    patientAddressText: this.advancedSearchParams.addressText,
                    facilityId: this.advancedSearchParams.facilityId,
                    practitionerId: this.advancedSearchParams.practitionerId,
                    issueDateFrom: this.advancedSearchParams.issueDateFrom,
                    issueDateTo: this.advancedSearchParams.issueDateTo,
                    dueDateFrom: this.advancedSearchParams.dueDateFrom,
                    dueDateTo: this.advancedSearchParams.dueDateTo,
                    returnDateFrom: this.advancedSearchParams.returnDateFrom,
                    returnDateTo: this.advancedSearchParams.returnDateTo,
                    issueMethod: this.advancedSearchParams.issueMethod,
                    issueReason: this.advancedSearchParams.issueReason,
                    thresholdMin: parseFloat(this.advancedSearchParams.thresholdMin),
                    thresholdMax: parseFloat(this.advancedSearchParams.thresholdMax),
                    testStatus: chosenStatusArray,
                    pageSize: this.advancedSearchParams.pageSize,
                    offset: this.offset,
                    sortColumn: this.sortColumn,
                    sortType: this.sortType,
                    testNumber: this.advancedSearchParams.testNumber
                }
                try {
                    const date = new Date()
                    var downloadUrl = asPDF ? `${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/tests/search/pdf` : `${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/tests/search/csv`
                    const response = await axios.post(downloadUrl, searchModel, {
                        responseType: 'blob' // Set the responseType to 'blob'
                    })
                    const url = asPDF ? window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' })) : window.URL.createObjectURL(new Blob([response.data]))
                    const link = document.createElement('a')
                    link.href = url
                    if (asPDF) {
                        link.setAttribute('download', `Test_Data_${date.toLocaleDateString()}.pdf`)
                    } else {
                        link.setAttribute('download', `Test_Data_${date.toLocaleDateString()}.csv`)
                    }
                    document.body.appendChild(link)
                    link.click()
                    link.remove()
                } catch {
                    this.isBusy = false

                    this.setNotification({
                        type: 'error',
                        title: 'Error',
                        message: 'Something went wrong. Please try again.'
                    })
                } finally {
                    this.isBusy = false
                }
            }
        },

        async getResults () {
            this.checkForm()

            if (!Object.keys(this.errors).length) {
                const chosenStatusArray = []
                if (this.advancedSearchParams.testStatus) {
                    chosenStatusArray.push(this.advancedSearchParams.testStatus)
                }

                const searchModel = {
                    organisationId: this.advancedSearchParams.organisationId,
                    patientText: this.advancedSearchParams.queryText,
                    patientAddressText: this.advancedSearchParams.addressText,
                    facilityId: this.advancedSearchParams.facilityId,
                    practitionerId: this.advancedSearchParams.practitionerId,
                    issueDateFrom: this.advancedSearchParams.issueDateFrom,
                    issueDateTo: this.advancedSearchParams.issueDateTo,
                    dueDateFrom: this.advancedSearchParams.dueDateFrom,
                    dueDateTo: this.advancedSearchParams.dueDateTo,
                    returnDateFrom: this.advancedSearchParams.returnDateFrom,
                    returnDateTo: this.advancedSearchParams.returnDateTo,
                    issueMethod: this.advancedSearchParams.issueMethod,
                    issueReason: this.advancedSearchParams.issueReason,
                    thresholdMin: parseFloat(this.advancedSearchParams.thresholdMin),
                    thresholdMax: parseFloat(this.advancedSearchParams.thresholdMax),
                    testStatus: chosenStatusArray,
                    pageSize: this.advancedSearchParams.pageSize,
                    offset: this.offset,
                    sortColumn: this.sortColumn,
                    sortType: this.sortType,
                    testNumber: this.advancedSearchParams.testNumber
                }

                try {
                    this.isBusy = true
                    this.advancedSearchParams.hasSearchBeenExecuted = true

                    const response = await axios.post(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/tests/search`, searchModel)

                    this.setSearchResultsInSearchStore(response.data)
                } catch {
                    this.isBusy = false
                    this.setNotification({
                        type: 'error',
                        title: 'Error',
                        message: 'Something went wrong. Please try again.'
                    })
                } finally {
                    this.isBusy = false
                }
            }
        },

        async chooseOrganisation () {
            await this.clearAdvancedFacilityIdAndAdvancedPractitionerIdInSearchStore()

            if (this.advancedSearchParams.organisationId) {
                await this.getFacilitiesByOrganisation(this.advancedSearchParams.organisationId)
                await this.getPractitionersByOrganisation(this.advancedSearchParams.organisationId)
                this.facilityListDisabled = false
                this.practitionerListDisabled = false
            } else {
                this.facilityListDisabled = true
                this.practitionerListDisabled = true
            }
        },

        sortingChanged (e) {
            switch (e.sortBy) {
                case 'patientName':
                    this.sortColumn = 'PATIENT_NAME'
                break

                case 'testNumber':
                    this.sortColumn = 'TEST_NUMBER'
                break

                case 'patientIdentifier':
                    this.sortColumn = 'PATIENT_IDENTIFIER'
                break

                case 'deviceNumber':
                    this.sortColumn = 'DEVICE_NUMBER'
                break

                case 'patientDOB':
                    this.sortColumn = 'PATIENT_DOB'
                break

                case 'patientPostalCode':
                    this.sortColumn = 'POSTCODE'
                break

                case 'issuedDate':
                    this.sortColumn = 'ISSUED_DATE'
                break

                case 'returnDate':
                    this.sortColumn = 'RETURN_DATE'
                break

                case 'dueDate':
                    this.sortColumn = 'DUE_DATE'
                break

                case 'gdEstimatedDeliveryDate':
                    this.sortColumn = 'EDD'
                break

                case 'status':
                    this.sortColumn = 'STATUS'
                break

                case 'practitionerName':
                    this.sortColumn = 'PRACTITIONER_NAME'
                break

                case 'facilityName':
                    this.sortColumn = 'FACILITY_NAME'
                break

                case 'organisationName':
                    this.sortColumn = 'ORGANISATION_NAME'
                break

                case 'dateUpdated':
                    this.sortColumn = 'LAST_UPDATED_DATE'
                break
            }

            this.sortDesc = e.sortDesc

            if (this.sortDesc) {
                this.sortType = 'DESCENDING'
            } else {
                this.sortType = 'ASCENDING'
            }

            this.getResults()
        },

        pageChange (value) {
            this.currentPage = value
            this.offset = (value - 1) * this.results.pageSize
            this.getResults()
        },

        checkForm () {
            this.errors = {}

            if (this.advancedSearchParams.organisationId === '') {
                this.errors.organisationId = ['You must choose an organisation']
            }
        },

        refreshTable () {
            this.$refs.table.refresh()
        },

        cancel () {
            this.practitionerListDisabled = true
            this.facilityListDisabled = true
            this.clearSearchStore()
        },

        handleChangePageSize () {
            this.currentPage = 1
            this.offset = 0

            this.getResults()
        },

        clearIssueDates () {
            this.advancedSearchParams.issueDateFrom = null
            this.advancedSearchParams.issueDateTo = null
        },

        clearReturnDates () {
            this.advancedSearchParams.returnDateFrom = null
            this.advancedSearchParams.returnDateTo = null
        },

        clearDueDates () {
            this.advancedSearchParams.dueDateFrom = null
            this.advancedSearchParams.dueDateTo = null
        },

        clearThresholds () {
            this.advancedSearchParams.thresholdMin = null
            this.advancedSearchParams.thresholdMax = null
        }
    }
}
</script>
