<template>
    <div>
        <Loader v-if="loading" />

        <div v-else>
            <div class="row mb-4 no-gutters">
                <div class="col-12 d-flex flex-wrap justify-content-between flex-row-gap-20">
                    <div>
                        <Breadcrumbs :dynamicTitle="`${organisation.name} - Test #${test.testNumber}`" :testStatus="test.status" />
                    </div>
                    <div class="text-right center-mobile results-button-container-parent">
                        <div class="results-button-container text-right">
                            <div class="results-button-wrapper" v-if="organisation.isTrial != true && !test.latestResultsReviewed && test.inReviewStatus != null && (test.status == TestStatus.ERROR || test.status == TestStatus.FAILED || test.inReviewStatus == TestStatus.ERROR || test.inReviewStatus == TestStatus.FAILED)">
                                <button :disabled="isBusy" class="btn" :class="{disabled : isBusy}" @click="setAsAccepted()"><img src="@/assets/images/icons/accept-icon.svg" /><span>Accept</span></button>
                            </div>
                            <div class="results-button-divider" v-if="organisation.isTrial != true && !test.latestResultsReviewed && test.inReviewStatus != null && (test.status == TestStatus.ERROR || test.status == TestStatus.FAILED || test.inReviewStatus == TestStatus.ERROR || test.inReviewStatus == TestStatus.FAILED)">
                                <span></span>
                            </div>
                            <div class="results-button-wrapper" v-if="organisation.isTrial != true && (test.status == TestStatus.REPORTED || test.status == TestStatus.ERROR || test.status == TestStatus.FAILED || test.inReviewStatus == TestStatus.ERROR || test.inReviewStatus == TestStatus.FAILED)">
                                <button :disabled="isBusy" class="btn" :class="{disabled : isBusy}" @click="changeTestToReturned()"><img src="@/assets/images/icons/set-return-icon.svg" /><span>Set as Returned</span></button>
                            </div>
                            <div class="results-button-divider" v-if="organisation.isTrial != true && (test.status == TestStatus.REPORTED || test.status == TestStatus.ERROR || test.status == TestStatus.FAILED || test.inReviewStatus == TestStatus.ERROR || test.inReviewStatus == TestStatus.FAILED)">
                                <span></span>
                            </div>
                            <div class="results-button-wrapper" v-if="organisation.isTrial != true && test.orderNumber !== null && test.method !== IssueMethod.IN_PERSON">
                                <button :disabled="isBusy" class="btn" :class="{disabled : isBusy}" @click="reorderTest()"><img src="@/assets/images/icons/reorder-icon.svg" /><span>Reorder test</span></button>
                            </div>
                            <div class="results-button-divider" v-if="organisation.isTrial != true && test.orderNumber !== null && test.method !== IssueMethod.IN_PERSON">
                                <span></span>
                            </div>
                            <div class="results-button-wrapper">
                                <div :disabled="isBusy" class="btn" :class="{disabled : isBusy}"><img src="@/assets/images/icons/download-icon.svg" />
                                    <b-dropdown right text="Download as" variant="header-dropdown">
                                        <b-dropdown-item class="dropdown-item" @click="downloadResult(false)">.CSV</b-dropdown-item>
                                        <b-dropdown-item class="dropdown-item" @click="downloadResult(true)">.PDF</b-dropdown-item>
                                    </b-dropdown>
                                </div>
                            </div>
                            <div v-if="test.status != TestStatus.CANCELLED" class="results-button-divider">
                                <span></span>
                            </div>
                            <div v-if="test.status != TestStatus.CANCELLED" class="results-button-wrapper">
                                <button :disabled="isBusy" class="btn results-button-last cancel" :class="{disabled : isBusy}" @click="cancelTest()"><img src="@/assets/images/icons/cancel-icon.svg" /><span>Cancel test</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex flex-wrap flex-row-gap-20">

                <div v-if="test.replacesTestId != null" class="result-banner replaces">
                    This test is a replacement for - <span @click="viewTest(test.replacesTestId)" class="underlined text-capitalize cursor-pointer">Test #{{ test.replacesTestNumber }}</span>
                </div>

                <div v-if="test.replacedByTestId != null" class="result-banner reordered">
                    This test has been reordered - <span @click="viewTest(test.replacedByTestId)" class="underlined text-capitalize cursor-pointer">Test #{{ test.replacedByTestNumber }}</span>
                </div>

                <div v-if="test.latestResultsReviewed === false && test.inReviewStatus" class="result-banner in-review">
                    This test is in review. Pending status is <testStatuses :status="test.inReviewStatus" />
                </div>

                <div v-if="test.latestResultsReviewed && test.replacedByTestId == null" class="result-banner">
                    The {{ testResults.status == TestStatus.ERROR ? "errored" : "failed" }} result for this test was accepted by {{ testResults.resultsReviewedByName }} on {{ testResults.resultsReviewedDateTime | moment("DD/MM/YYYY") }} at {{ testResults.resultsReviewedDateTime | moment("HH:mm") }}
                </div>

                <div v-if="test.previouslyBeenSetAsReturned && testResults.statusChangeDateTime != null" class="result-banner overridden">
                    The {{ testResults.statusChangedFrom == TestStatus.ERROR ? "errored" : "failed" }} result for this test was overridden by {{ testResults.statusChangedByName }} on {{ testResults.statusChangeDateTime | moment("DD/MM/YYYY") }} at {{ testResults.statusChangeDateTime | moment("HH:mm") }}
                </div>
            </div>

            <PatientDetails
                v-if="Object.keys(patientDetails).length"
                :patient="patientDetails"
                :organisation="organisation"
                :gdEstimatedDeliveryDate="test.gdEstimatedDeliveryDate"
                class="mt-4"
            />

            <div class="mt-4">
                <b-tabs content-class="mt-0">
                    <b-tab title="Test Results" active>
                        <TestResults
                            :test="test"
                            :organisation="organisation"
                            :results="testResults"
                            :patient="patientDetails"
                        />
                    </b-tab>
                    <b-tab :title="`Previous Tests (${previousPatientTests.totalCount})`">
                            <div class="card text-sm">
                                <div class="card-body">
                                    <PatientTests
                                        :tests="previousPatientTests"
                                        :patientId="patientDetails.id"
                                        :organisationId="$route.params.oId"
                                        :showUpcomingTests="false"
                                    />
                                </div>
                            </div>
                        </b-tab>
                        <b-tab :title="`Upcoming Tests (${upcomingPatientTests.totalCount})`">
                            <div class="card text-sm">
                                <div class="card-body">
                                    <PatientTests
                                        :tests="upcomingPatientTests"
                                        :patientId="patientDetails.id"
                                        :organisationId="$route.params.oId"
                                        :showUpcomingTests="true"
                                    />
                                </div>
                            </div>
                        </b-tab>
                </b-tabs>
            </div>

            <TestEvents
                v-if="testEvents.length"
                :events="testEvents"
                :results="testResults"
            />

            <TestNotes
                v-if="testNotes.length"
                :notes="testNotes"
                :testId="$route.params.tId"
                :organisationId="$route.params.oId"
            />
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import Breadcrumbs from '@/partials/site-structure/BreadcrumbsComponent.vue'
import Loader from '@/partials/Loader.vue'
import PatientDetails from '@/components/PatientDetailsComponent.vue'
import TestResults from '@/components/TestResultsComponent.vue'
import TestEvents from '@/components/TestEventsComponent.vue'
import TestNotes from '@/components/TestNotesComponent.vue'
import PatientTests from '@/components/PatientTestsComponent.vue'
import TestStatus from '@/enums/TestStatus'
import CancelTestModal from '@/components/modals/CancelTestModalComponent.vue'
import ChangeTestToReturnedModal from '@/components/modals/ChangeTestToReturnedModalComponent.vue'
import ReorderTestModal from '@/components/modals/ReorderTestModalComponent.vue'
import AcceptTestStatusModal from '@/components/modals/AcceptTestStatusModalComponent.vue'
import TestStatuses from '@/components/TestStatusesComponent.vue'
import IssueMethod from '@/enums/IssueMethod'
export default {
    name: 'ViewTest',

    components: {
        Loader,
        PatientDetails,
        TestResults,
        TestEvents,
        TestNotes,
        PatientTests,
        Breadcrumbs,
        TestStatuses
    },

    data () {
        return {
            loading: true,
            isBusy: false,
            organisation: {},
            test: {},
            patientDetails: {},
            testNotes: [],
            testEvents: [],
            testResults: {},
            previousPatientTests: {},
            upcomingPatientTests: {},
            TestStatus,
            CancelTestModal,
            patientTestsKey: 0,
            AcceptTestStatusModal,
            IssueMethod
        }
    },

    async mounted () {
        try {
            await this.getOrganisation()
            await this.getTest()
            await this.getPatientDetails()
            await this.getTestEvents()
            await this.getTestNotes()
            await this.getUpcomingPatientTests()
            await this.getPreviousPatientTests()

            if (this.test.resultId) {
                await this.getTestResults()
            }
        } catch {
            this.setNotification({
                type: 'error',
                title: 'Error',
                message: 'Something went wrong while fetching the data. Please try again'
            })
        } finally {
            this.loading = false
        }
    },

    watch: {
        shouldRefreshViewTestComponent: async function (val) {
            if (val === true) {
                this.loading = true
                this.isBusy = true

                this.test = {}
                this.patientDetails = {}
                this.testNotes = []
                this.testEvents = []
                this.testResults = {}
                this.previousPatientTests = {}
                this.upcomingPatientTests = {}

                try {
                    await this.getOrganisation()
                    await this.getTest()
                    await this.getPatientDetails()
                    await this.getTestEvents()
                    await this.getTestNotes()
                    await this.getUpcomingPatientTests()
                    await this.getPreviousPatientTests()

                    if (this.test.resultId) {
                        await this.getTestResults()
                    }
                } catch {
                    this.loading = false
                    this.isBusy = false
                    this.setShouldRefreshViewTestComponent(false)
                    this.setNotification({
                        type: 'error',
                        title: 'Error',
                        message: 'Something went wrong while fetching the data. Please try again'
                    })
                } finally {
                    this.loading = false
                    this.isBusy = false
                    this.setShouldRefreshViewTestComponent(false)
                }
            }
        }
    },

    computed: {
        ...mapGetters({
            shouldRefreshViewTestComponent: 'AppStore/GetShouldRefreshViewTestComponent'
        })
    },

    methods: {
        ...mapActions({
            setNotification: 'NotificationStore/SetNotification',
            setShouldRefreshViewTestComponent: 'AppStore/SetShouldRefreshViewTestComponent'
        }),

        async getOrganisation () {
            const response = await axios.get(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/organisations/${this.$route.params.oId}`)

            this.organisation = response.data
        },

        async getTest () {
            const response = await axios.get(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/tests/${this.$route.params.oId}/${this.$route.params.tId}`)

            this.test = response.data
        },

        async getPatientDetails () {
            const response = await axios.get(`${process.env.VUE_APP_AXIOS_PATIENT_BASE_URL}/api/v1/patients/${this.$route.params.oId}/${this.test.patientId}`)

            this.patientDetails = response.data
        },

        async getTestNotes () {
            const response = await axios.get(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/testnotes/${this.$route.params.oId}/${this.$route.params.tId}/100/0`)

            this.testNotes = response.data.results
        },

        async getTestEvents () {
            const response = await axios.get(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/testevents/${this.$route.params.oId}/${this.$route.params.tId}`)

            this.testEvents = response.data
        },

        async getTestResults () {
            const response = await axios.get(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/results/${this.$route.params.oId}/${this.test.resultId}`)

            this.testResults = response.data
        },

        async getUpcomingPatientTests () {
            const searchModel = {
                organisationId: this.$route.params.oId,
                facilityId: '',
                practitionerId: '',
                patientId: this.patientDetails.id,
                pageSize: 10,
                offset: 0,
                sortColumn: 'TEST_NUMBER',
                sortType: 'DESCENDING',
                testStatus: [TestStatus.NEW, TestStatus.ISSUED]
            }

            const response = await axios.post(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/tests/search`, searchModel)
            this.upcomingPatientTests = response.data
        },

        async getPreviousPatientTests () {
            const searchModel = {
                organisationId: this.$route.params.oId,
                facilityId: '',
                practitionerId: '',
                patientId: this.patientDetails.id,
                pageSize: 10,
                offset: 0,
                sortColumn: 'TEST_NUMBER',
                sortType: 'DESCENDING',
                testStatus: [TestStatus.RETURNED, TestStatus.COMPLETE, TestStatus.CANCELLED, TestStatus.REPORTED, TestStatus.FAILED, TestStatus.ERROR]
            }

            const response = await axios.post(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/tests/search`, searchModel)
            this.previousPatientTests = response.data
        },

        async cancelTest () {
            this.$modal.show(CancelTestModal, { testExists: true, organisationId: this.$route.params.oId, testId: this.test.id }, { width: '400px' }, { 'before-close': this.reloadTest })
        },

        async changeTestToReturned () {
            this.$modal.show(ChangeTestToReturnedModal, { testExists: true, organisationId: this.$route.params.oId, testId: this.test.id }, { width: '400px' }, { 'before-close': this.reloadTest })
        },

        async setAsAccepted () {
            this.$modal.show(AcceptTestStatusModal, { test: this.test }, { width: '400px' }, { 'before-close': this.reloadTest })
        },

        async reorderTest () {
            this.$modal.show(ReorderTestModal, { organisationId: this.$route.params.oId, test: this.test }, { width: '400px' }, { 'before-close': this.reloadTest })
        },

        async reloadTest () {
            await this.getTest()
            await this.getTestEvents()
            await this.getTestNotes()

            if (this.test.resultId) {
                await this.getTestResults()
            }
        },

        async downloadResult (asPDF) {
            this.isBusy = true
            try {
                var downloadUrl = asPDF ? `${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/tests/${this.$route.params.oId}/${this.$route.params.tId}/pdf` : `${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/tests/${this.$route.params.oId}/${this.$route.params.tId}/csv`
                const response = await axios.get(downloadUrl, {
                        responseType: 'blob' // Set the responseType to 'blob'
                    })
                const url = asPDF ? window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' })) : window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                if (asPDF) {
                    link.setAttribute('download', `Test_Data_${this.$route.params.tId}.pdf`)
                } else {
                    link.setAttribute('download', `Test_Data_${this.$route.params.tId}.csv`)
                }
                document.body.appendChild(link)
                link.click()
                link.remove()
            } catch {
                this.isBusy = false
                this.setNotification({
                    type: 'error',
                    title: 'Error',
                    message: 'Something went wrong. Please try again.'
                })
            } finally {
                this.isBusy = false
            }
        },

        async viewTest (testId) {
            await this.$router.push({ name: 'ViewTest', params: { oId: this.$route.params.oId, tId: testId } })
        }
    }
}
</script>
